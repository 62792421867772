@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
html, body {
  height: auto !important;
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html, body {
  font-family: Lato, sans-serif;
  color: #353535; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    html, body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; } }

h1, h2, h3 {
  font-weight: 400; }

h1 {
  font-size: 60px;
  color: #9B9B9B; }

a {
  text-decoration: none; }

.app__wrapper {
  display: flex;
  margin-bottom: 90px; }

.app__sidebar {
  width: 325px;
  padding: 60px;
  border-right: 10px solid #E5E5E5;
  min-height: calc(100vh - 90px); }

.app__main {
  width: calc(100vw - 325px);
  padding: 0 60px 60px; }

.app__footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 90px; }

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards; }

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 30px auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both; }

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards; }

@keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

@keyframes scale {
  0%, 100% {
    transform: none; }
  50% {
    transform: scale3d(1.5, 1.5, 1); } }

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142; } }

.banner {
  padding-top: 130px;
  border-bottom: 4px solid #E5E5E5; }

.breadcrumbs {
  position: absolute;
  width: calc(100vw - 325px);
  top: 0;
  left: 325px;
  list-style: none;
  margin: 0;
  padding: 10px 60px;
  background: #E5E5E5; }
  .breadcrumbs li {
    list-style: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px 5px 0;
    color: #9B9B9B;
    display: inline-block; }
    .breadcrumbs li a {
      color: #9B9B9B; }
    .breadcrumbs li:after {
      content: '\00bb';
      display: inline-block;
      padding: 0 0 0 5px;
      top: -3px; }
    .breadcrumbs li:last-of-type {
      color: #6C6C6C; }
      .breadcrumbs li:last-of-type:after {
        content: ''; }

.create-topic__form {
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  max-height: 0px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
  padding: 0px 30px 0px;
  border-bottom: 1px solid #E5E5E5;
  border-top: 1px solid #E5E5E5; }
  .create-topic__form.visible {
    max-height: 500px;
    padding: 0px 30px 20px; }
  .create-topic__form .txfield {
    width: calc(30% - 20px); }

.app__footer {
  padding: 0 60px;
  background: #2E2E2E;
  line-height: 90px;
  color: white;
  font-size: 12px; }
  .app__footer ul, .app__footer li {
    list-style: none;
    padding: 0;
    margin: 0; }
  .app__footer li {
    display: inline; }

.image-preview {
  position: relative;
  width: 50%;
  padding: 30px;
  margin: 20px auto 0;
  text-align: center; }
  .image-preview img {
    width: 100%; }
  .image-preview .image-buttons {
    z-index: 1;
    position: absolute;
    top: 50px;
    right: 50px; }
  .image-preview__review-img {
    max-height: 200px;
    padding: 20px 0; }

.login__wrapper {
  min-width: 550px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center; }

.login__form {
  width: 550px; }
  .login__form .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 20px 0; }
    .login__form .form-footer a {
      display: block;
      font-size: 14px; }
  .login__form .sign-in-button {
    border-radius: 0;
    box-shadow: none;
    color: white;
    font-size: 16px;
    padding: 10px 50px; }

.error-message {
  color: #cc0000; }

.message-list__toolbar {
  display: flex;
  padding: 30px 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E5E5E5; }
  .message-list__toolbar button {
    margin-left: 10px;
    box-shadow: none; }

.message-list__filter-sort {
  width: 50%; }
  .message-list__filter-sort .fs-item {
    margin-left: 10px; }

.modal__emoji-picker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.platform-select__wrapper {
  display: flex;
  padding: 30px 20px 50px; }

.platform-select__checkboxes {
  width: 50%; }

.platform-select__images {
  width: 50%;
  min-height: 150px;
  position: relative;
  text-align: center; }
  .platform-select__images .platform-logo {
    position: absolute;
    height: 150px;
    transform: translateX(-50%) scale(0.5);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0; }
    .platform-select__images .platform-logo.visible {
      opacity: 1;
      transform: translateX(-50%) scale(1); }
  .platform-select__images.two-up .apple {
    margin-top: 15px;
    margin-left: 50px; }
  .platform-select__images.two-up .android {
    margin-top: -15px;
    margin-left: -50px; }

.app__sidebar {
  color: #6C6C6C; }
  .app__sidebar img {
    width: 100%;
    transition: all 0.0s; }
    .app__sidebar img:hover {
      transition: all 3s linear;
      filter: hue-rotate(720deg); }
  .app__sidebar button.logout {
    padding: 0;
    font-weight: 700;
    color: #6C6C6C; }
    .app__sidebar button.logout:hover {
      background: none; }
  .app__sidebar h2.client-name {
    font-size: 30px;
    line-height: 36px;
    padding-top: 80px; }
  .app__sidebar .sidebar-links {
    list-style: none;
    border: 4px solid #E5E5E5;
    border-left: none;
    border-right: none;
    padding: 20px 0; }
    .app__sidebar .sidebar-links li {
      padding: 20px 0; }
    .app__sidebar .sidebar-links a {
      font-size: 14px;
      font-weight: 700;
      color: #6C6C6C;
      text-transform: uppercase; }
      .app__sidebar .sidebar-links a:after {
        position: relative;
        transition: all 0.3s ease;
        content: '\00bb';
        padding: 0 5px;
        opacity: 0;
        top: -1px;
        margin-left: -10px; }
      .app__sidebar .sidebar-links a.active {
        color: #06A4F0; }
        .app__sidebar .sidebar-links a.active:after {
          opacity: 1;
          margin-left: 0px; }

.submit-buttons {
  display: flex;
  align-items: center;
  justify-content: center; }
  .submit-buttons .progress {
    margin-right: 20px;
    display: none; }
    .submit-buttons .progress.visible {
      display: inline; }
